<script>
export default {
  created() {
    this.initParamsUpdateHook()
  },
  methods: {
    redrawRouterView() {
      this.hideRouterView = true
      this.$nextTick(() => {
        this.hideRouterView = false
      })
    },
    initParamsUpdateHook() {
      this.$router.beforeEach((to, from, next) => {
        next()
        if (from.name == to.name) this.redrawRouterView()
      })
    },
  },
  watch: {
    currentLocale() {
      this.redrawRouterView()
    },
  },
}
</script>
