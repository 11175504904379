<template>
  <div class="popup-base" v-if="show">
    <div class="blackout" @click="close" />

    <div
      class="popup"
      :style="{
        'background-color': transparent ? 'transparent' : 'white',
        'box-shadow': transparent ? 'none' : '0px 0px 10px rgba(0, 0, 0, 0.5)',
      }"
    >
      <div class="popup-header" v-if="!noHeader">
        <div class="title oneline">{{ title }}</div>
        <div class="close" @click="close">
          <md-icon>close</md-icon>
        </div>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: ['show', 'title', 'noHeader', 'transparent'],
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
.popup-base {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 16;

  > .blackout {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: -1px;
    bottom: 0;
    z-index: -1;
  }

  > .popup {
    padding: 10px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    font-weight: 300;
    font-size: 15px;
    transition: 0.25s all;
    max-width: 95%;
    max-height: 95vh;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    overscroll-behavior: none;

    > .popup-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      > .title {
        font-size: 17px;
        margin-right: 5px;
        font-weight: 400;
      }
      > .close {
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        cursor: pointer;
      }
    }
  }
}
</style>
