<script>
export default {
  created() {
    this.$router.beforeEach((to, from, next) => {
      const activeBlokersKeys = []
      for (const blockerKey of Object.keys(this.$store.navigationBlockers)) {
        const blocker = this.$store.navigationBlockers[blockerKey]
        if (blocker != null) {
          blocker()
          this.$store.navigationBlockers[blockerKey] = null
          activeBlokersKeys.push(blockerKey)
        }
      }
      if (activeBlokersKeys.length > 0) {
        next(false)
        console.log(
          '[NavBlockers] Navigation blocked because of next blockers: ' +
            activeBlokersKeys.join(', '),
        )
      } else next()
    })
  },
}
</script>
