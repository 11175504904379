<template>
  <div class="check-attribute">
    <md-checkbox v-model="$attr.value">{{ attribute.title }}</md-checkbox>
    <div class="subtitle">{{ $stripHtml(attribute.subTitle) }}</div>
  </div>
</template>

<script>
import AttributeMixin from '@/mixins/Attribute'

export default {
  props: ['attribute'],
  mixins: [AttributeMixin],
  data() {
    return {
      defaultValue: this.attribute.defaultValue ?? false,
    }
  },
  methods: {
    $setValueHandler(value) {
      if (value === 'true') value = true
      if (value === 'false') value = false
      this.$attr.value = Boolean(value)
    },
  },
}
</script>

<style lang="scss">
.check-attribute {
  .md-checkbox {
    margin: 0;
    margin-top: 8px;
  }
  .subtitle {
    font-size: 12px;
    margin-top: -5px;
    margin-left: 36px;
    line-height: 1;
  }
}
</style>
