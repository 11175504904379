<template>
  <div class="datetime-attribute" ref="root">
    <div class="label">{{ attribute.title }}</div>
    <md-datepicker
      class="w-50 date"
      :value="$attr.value"
      @input="handleDatepickerInput"
      :md-disabled-dates="getIsDateDisabled"
      :md-disabled-input="true"
    >
      <label>{{ $attr.value ? '' : $t('choose.notSelected') }}</label>
    </md-datepicker>

    <vue-timepicker
      class="time"
      ref="time"
      :value="timeValue"
      @input="handleTimepickerInput"
      :hour-label="$t('time.hour')"
      :minute-label="$t('time.minute')"
      :input-width="timeWidth + 'px'"
      :hour-range="currentHourRange"
      :minute-range="currentMinuteRange"
      :hide-disabled-items="true"
    />

    <div class="underlabels">
      <span class="date-label">{{ $t('choose.date') }}</span>
      <span class="time-label">{{ $t('choose.time') }}</span>
    </div>
  </div>
</template>

<script>
import { structuredClone } from 'core-js'
import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import AttributeMixin from '@/mixins/Attribute'
import { formatDate } from '../../lib/formatDate'
import { UNITS } from '../../lib/formatTimeDisplay'

export default {
  props: ['attribute', 'offer', 'subOffer', 'subSubOffer'],
  mixins: [AttributeMixin],
  components: { VueTimepicker },
  data() {
    return {
      timeWidth: 180,
      timeValue: { HH: '00', mm: '00' },
      defaultValue: new Date(),
      $manualSetEventImplementation: true,
      currentHourRange: [],
      currentMinuteRange: [],
      range: {},
    }
  },
  events: {
    'attr-value-changed'(attrId) {
      if (attrId == this.durationQtyAttr?.id) this.updateCurrentRanges()
    },
  },
  created() {
    this.updateRange()
  },
  mounted() {
    this.$waitFor(() => this.$refs.time?.$el).then(() => {
      this.$refs.time.$el.children[0].placeholder = ''
      this.timeWidth =
        this.$refs.root.getBoundingClientRect().right -
        this.$refs.time.$el.getBoundingClientRect().left
    })
  },
  methods: {
    updateRange() {
      //У range приходящего с сервера при обращении к Object.keys ключи могут быть неотсортированы
      this.range = this.attribute.range
      this.setValue(this.$attr.value)
    },
    /**
     * @param {Date} date
     */
    getIsDateDisabled(date) {
      const availableDays = Object.keys(this.range)
      return !availableDays.includes(formatDate(date))
    },
    handleTimepickerInput(e) {
      const currentMinutes = this.$attr.value.getMinutes()
      const currentHours = this.$attr.value.getHours()
      const newHours = Number(e.HH)
      const newMinutes = Number(e.mm)

      if (newHours != currentHours) this.$attr.value.setHours(newHours)
      if (newMinutes != currentMinutes) this.$attr.value.setMinutes(newMinutes)
      this.setValue(this.$attr.value)
    },
    handleDatepickerInput(e) {
      const currentDate = this.$attr.value
      const newDate = new Date(e)
      newDate.setHours(currentDate.getHours())
      newDate.setMinutes(currentDate.getMinutes())
      newDate.setSeconds(currentDate.getSeconds())
      newDate.setMilliseconds(currentDate.getMilliseconds())

      if (newDate.getTime() != currentDate.getTime()) this.setValue(newDate)
    },
    updateCurrentRanges() {
      const currentDay = formatDate(this.$attr.value)

      this.currentHourRange = []
      this.currentMinuteRange = []
      if (!Object.keys(this.range).includes(currentDay)) return

      const currentRangeDay = this.getCurrentRangeDayFilteredByDuration()

      this.currentHourRange = Object.keys(currentRangeDay).sort()

      const hours = Object.keys(currentRangeDay).map(hour => Number(hour))
      const hour = Number(this.timeValue.HH)
      if (!hours.includes(hour)) this.currentMinuteRange = []
      else this.currentMinuteRange = currentRangeDay[Number(this.timeValue.HH)]
    },
    getCurrentRangeDayFilteredByDuration() {
      const currentDay = formatDate(this.$attr.value)
      const rangeDay = structuredClone(this.range[currentDay])
      const durationMinutes = this.getDurationQtyAttrMinutes()

      if (!durationMinutes) return rangeDay
      if (
        !this.attribute.properties.TIME_UNIT ||
        !this.attribute.properties.SLOT_DURATION
      )
        return rangeDay

      const stepMinutes =
        (UNITS[this.attribute.properties.TIME_UNIT] *
          this.attribute.properties.SLOT_DURATION) /
        UNITS['MINUTES']

      for (const hour of Object.keys(rangeDay).sort()) {
        for (const minute of rangeDay[hour]) {
          const date = new Date(currentDay)
          date.setHours(hour)
          date.setMinutes(minute)

          let minutesPassed = 0
          while (minutesPassed < durationMinutes) {
            date.setTime(date.getTime() + stepMinutes * UNITS['MINUTES'])
            const doesRangeContainsFullDate = this.doesRangeContainsFullDate(
              date,
            )
            if (!doesRangeContainsFullDate) break
            minutesPassed += stepMinutes
          }
          const isAllowed = minutesPassed >= durationMinutes
          if (!isAllowed) {
            if (!rangeDay[hour]) continue
            rangeDay[hour] = rangeDay[hour].filter(
              rangeMinute => rangeMinute != minute,
            )
            if (rangeDay[hour].length == 0) delete rangeDay[hour]
          }
        }
      }
      return rangeDay
    },
    getDurationQtyAttrMinutes() {
      const durationQtyAttr = this.durationQtyAttr
      if (!durationQtyAttr) return null

      const attrValue =
        this.$store.attributesSaves[durationQtyAttr.id]?.getValue() ||
        durationQtyAttr.defaultValue

      const durationMinutes =
        (UNITS[durationQtyAttr.unit] * attrValue) / UNITS['MINUTES']

      return durationMinutes
    },
    doesRangeContainsFullDate(date) {
      const day = formatDate(date)
      const hour = date.getHours()
      const minute = date.getMinutes()

      const rangeDay = this.range[day]
      if (!rangeDay) return false
      const rangeHour = rangeDay[hour]
      if (!rangeHour) return false
      if (!rangeHour.includes(minute)) return false

      return true
    },
    updateTimeValue() {
      const time = this.$attr.value
        .toTimeString()
        .split(' ')[0]
        .split(':')
        .slice(0, 2)
      if (this.timeValue?.HH != time[0] || this.timeValue?.mm != time[1]) {
        this.timeValue = { HH: time[0], mm: time[1] }
      }
    },
    /**
     * проверяет возможна ли такая дата, если нет, то исправляет на первую доступную
     * @param {Date} date
     * @returns {Date} validatedDate
     */
    validateNewValue(date) {
      date.setSeconds(0)
      date.setMilliseconds(0)

      //доступен ли такой день
      const availableDays = Object.keys(this.range).sort()
      if (availableDays.length == 0) {
        console.error('[DateTime Attribute] No available days')
        return date
      }

      if (!availableDays.includes(formatDate(date))) {
        date = new Date(availableDays[0])
      }

      //доступен ли такой час
      const availableHours = Object.keys(this.range[formatDate(date)])
        .map(hour => Number(hour))
        .sort()
      if (availableHours.length == 0) {
        console.error('[DateTime Attribute] No available hours')
        return date
      }

      if (!availableHours.includes(date.getHours())) {
        date.setHours(availableHours[0])
        date.setMinutes(0)
      }

      //доступна ли такая минута
      const availableMinutes = this.range[formatDate(date)][date.getHours()]
      if (availableMinutes.length == 0) {
        console.error('[DateTime Attribute] No available minutes')
        return date
      }

      if (!availableMinutes.includes(date.getMinutes())) {
        date.setMinutes(availableMinutes[0])
      }

      return date
    },
    setValue(date) {
      this.$attr.value = this.validateNewValue(date)
      this.$events.emit('attr-value-changed', this.attribute.id, date)
      this.updateTimeValue()
      this.updateCurrentRanges()
    },
    $setValueHandler(value) {
      if (typeof value == 'string' && String(Number(value)) == value)
        value = Number(value)
      value = new Date(value)
      this.setValue(value)
    },
    $getValue() {
      return new Date(this.$attr.value.normalize()).getTime()
    },
  },
  watch: {
    routerWidth() {
      this.$refs.time.$el.children[0].placeholder = ''
      this.timeWidth =
        this.$refs.root.getBoundingClientRect().right -
        this.$refs.time.$el.getBoundingClientRect().left
    },
    subOffer() {
      this.updateCurrentRanges()
    },
  },
  computed: {
    routerWidth() {
      return this.$store.routerWidth
    },
    durationQtyAttr() {
      const filterFunc = attr =>
        attr['@type'] == 'qty' &&
        attr.unit &&
        ['MINUTES', 'HOURS', 'DAYS'].includes(attr.unit)

      const durationQtyAttr =
        this.offer?.attributes?.find(filterFunc) ||
        this.subOffer?.attributes?.find(filterFunc) ||
        this.subSubOffer?.attributes?.find(filterFunc)

      return durationQtyAttr
    },
  },
}
</script>

<style lang="scss">
.datetime-attribute {
  position: relative;
  padding-bottom: 15px;

  .clear-btn {
    display: none;
  }

  > .label {
    font-size: 16px;
    color: #8b8b8b;
    transform: translateY(15px);
  }

  .md-clear {
    display: none;
  }

  .date {
    &::after {
      background-color: #e4e4e4;
      right: 25px;
    }
  }

  .time {
    position: absolute;
    left: 50%;
    top: 41px;

    input::-webkit-date-and-time-value {
      margin-top: 7px;
    }

    .display-time {
      border: none;
      border-bottom: 1px solid #e4e4e4;
    }

    .dropdown {
      .active {
        background: #a5a5a5;
      }
    }

    input {
      -webkit-text-fill-color: black !important;
    }
  }

  .underlabels {
    position: absolute;
    top: 75px;
    font-weight: 300;
    font-size: 13px;
    width: 100%;

    > * {
      -webkit-text-fill-color: black !important;
    }

    .date-label {
      display: inline-block;
    }
    .time-label {
      display: inline-block;
      position: absolute;
      left: 50%;
    }
  }
}
</style>
