<template>
  <div class="slots-attribute">
    <div class="label">{{ attribute.title }}</div>
    <div class="box" @click="popupActive = !popupActive">
      <md-icon>event</md-icon>
      <span v-if="!$attr.value.startTime || !$attr.value.endTime">{{
        attribute.subTitle
          ? $stripHtml(attribute.subTitle)
          : $t('choose.notSelected')
      }}</span>
      <span v-else>
        {{ displayValue }}
      </span>
    </div>

    <PopupBase
      :show="popupActive"
      @close="popupActive = false"
      :title="attribute.title"
    >
      <div class="month-selector">
        <div class="arrow" @click="selectDay(-30)">
          <md-icon>arrow_left</md-icon>
        </div>

        <div class="title">{{ dayMonthTitle }}</div>

        <div class="arrow" @click="selectDay(+30)">
          <md-icon>arrow_right</md-icon>
        </div>
      </div>
      <div class="day-selector">
        <div class="arrow" @click="selectDay(-1)">
          <md-icon>arrow_left</md-icon>
        </div>

        <div
          class="day"
          :class="{
            active: offset == 0,
            disabled: !isDayAvailableByOffset(offset),
          }"
          v-for="offset in [-2, -1, 0, 1, 2]"
          @click="selectDay(offset)"
          :key="offset"
        >
          <div class="day">
            {{ getDisplayDay(offset) }}
          </div>

          <div
            class="weekday"
            :class="{
              weekend: isWeekend(offset),
            }"
          >
            {{ getDisplayWeekday(offset) }}
          </div>
        </div>

        <div class="arrow" @click="selectDay(1)">
          <md-icon>arrow_right</md-icon>
        </div>
      </div>

      <div class="slots">
        <div
          class="group"
          v-for="group in getSlotsGroupsForDay(currentDay)"
          :key="group.title"
        >
          <div class="title">{{ group.title }}</div>
          <div class="slots">
            <div
              class="slot"
              :class="{
                active: isSlotActive(slot),
                forbidden: slot.forbidden,
              }"
              v-for="slot in group.slots"
              :key="slot.id"
              @click="selectSlot(slot)"
            >
              {{ slot.id }}
            </div>
          </div>
        </div>
      </div>

      <div class="button-wrapper">
        <div class="info">
          <div v-if="!($attr.value.startTime && $attr.value.endTime)">
            {{ $t('slots.chooseSlots') }}
          </div>
          <div v-else>{{ displayValue }}</div>
        </div>
        <div
          class="button"
          @click="popupActive = false"
          :class="{ active: $attr.value.startTime && $attr.value.endTime }"
        >
          {{ $t('materialUI.confirm') }}
        </div>
      </div>
    </PopupBase>
  </div>
</template>

<script>
import AttributeMixin from '@/mixins/Attribute'
import PopupBase from '../Popups/PopupBase.vue'
import { formatDate } from '../../lib/formatDate'

const SLOTS_GROUPS_SEPERATION = [
  { sort: 1, hour: 0, title: 'night' },
  { sort: 2, hour: 6, title: 'morning' },
  { sort: 3, hour: 12, title: 'afternoon' },
  { sort: 4, hour: 17, title: 'evening' },
].sort((a, b) => a.hour - b.hour)

export default {
  props: ['attribute'],
  mixins: [AttributeMixin],
  components: { PopupBase },
  data() {
    return {
      defaultValue: { startTime: null, endTime: null },
      $manualSetEventImplementation: true,
      popupActive: false,
      currentDay: null,
    }
  },
  created() {
    //если значение аттрибута уже существует, то ставим день к нему
    const value = this.$attr.value
    const startDay = formatDate(value.startTime)
    if (startDay && this.availableDays.includes(startDay)) {
      this.currentDay = startDay
    } else if (!this.availableDays.includes(this.currentDay)) {
      this.currentDay = this.availableDays[0]
    }
  },
  methods: {
    getOffsetDay(offset = 0, currentDay = this.currentDay) {
      const offsetDate = new Date(currentDay)
      offsetDate.setDate(offsetDate.getDate() + offset)
      return formatDate(offsetDate)
    },
    getSlotsByDay(day) {
      return this.slots[day].map(slot => {
        const startTime = new Date(slot.startTime)
        const endTime = new Date(slot.endTime)
        const id =
          startTime.toLocaleString(this.$t('datetimeFormat'), {
            hour: '2-digit',
            minute: '2-digit',
          }) +
          ' - ' +
          endTime.toLocaleString(this.$t('datetimeFormat'), {
            hour: '2-digit',
            minute: '2-digit',
          })
        return { ...slot, startTime, endTime, id }
      })
    },
    getSlotsGroupsForDay(day) {
      const slots = this.getSlotsByDay(day)
      const groups = []
      for (const slot of slots) {
        const slotHour = new Date(slot.startTime).getHours()
        let groupTitle = null
        let sort = 0

        for (const seperation of SLOTS_GROUPS_SEPERATION) {
          if (slotHour >= seperation.hour) {
            groupTitle = this.$t('dayTimes.' + seperation.title)
            sort = seperation.sort
          }
        }

        if (!groups.find(group => group.title == groupTitle))
          groups.push({ title: groupTitle, slots: [], sort })
        groups.find(group => group.title == groupTitle).slots.push(slot)
      }
      return groups.sort((a, b) => a.sort - b.sort)
    },
    selectSlot(slot) {
      if (slot.forbidden) return
      let current = this.$attr.value

      //если указанный слот входит в диапазон уже указанных, сбрасываем выделение
      if (
        current.startTime &&
        current.endTime &&
        slot.startTime >= current.startTime &&
        slot.endTime <= current.endTime
      ) {
        current.startTime = current.endTime = null
        this.setValue(this.$attr.value)
        return
      }

      if (!current.startTime || slot.startTime < current.startTime)
        current.startTime = slot.startTime
      if (!current.endTime || current.endTime < slot.endTime)
        current.endTime = slot.endTime

      if (this.isSlotsRangeForbidden(current)) {
        this.$attr.value = slot
        current = this.$attr.value
      }

      //ограничиваем по maxSlotsSelected
      const selectedSlots = this.getSelectedSlots(current)
      const maxSlots = this.attribute.maxSlotsSelected
      if (maxSlots && selectedSlots.length > maxSlots) {
        //выделяем последние возможные
        this.$attr.value = {
          forbidden: false,
          startTime: new Date(selectedSlots.slice(maxSlots * -1)[0].startTime), // [1,2,3,4].slice(-2) = [3,4]
          endTime: new Date(selectedSlots.slice(-1)[0].endTime), // [1,2,3,4].slice(-1) = [4]
        }
        current = this.$attr.value

        //если пытается выделить слот до существующих,
        //то выделять первые возможные
        if (slot.startTime < current.startTime) {
          this.$attr.value = slot
        }
      }

      this.setValue(this.$attr.value)
    },
    isSlotActive(slot) {
      return (
        slot.startTime >= this.$attr.value.startTime &&
        slot.endTime <= this.$attr.value.endTime
      )
    },
    selectDay(offset) {
      let finalDay = this.getOffsetDay(offset)
      const firstAvailable = this.availableDays[0]
      const lastAvailable = this.availableDays[this.availableDays.length - 1]

      //если день за границами,
      //то выбираем наиболее близкий
      if (new Date(finalDay) < new Date(firstAvailable))
        finalDay = firstAvailable
      if (new Date(finalDay) > new Date(lastAvailable)) finalDay = lastAvailable

      //если этот день в списке запрещен,
      //то выбираем наиболее близкий
      if (!this.isDayAvailable(finalDay)) {
        const dayDistanceSortFunc = (dayA, dayB) => {
          const dayADistance = Math.abs(new Date(dayA) - new Date(finalDay))
          const dayBDistance = Math.abs(new Date(dayB) - new Date(finalDay))
          return dayADistance - dayBDistance
        }

        const mostCloseLeftDays = [...this.availableDays]
          .filter(day => new Date(day) < new Date(finalDay))
          .sort(dayDistanceSortFunc)
        const mostCloseRightDays = [...this.availableDays]
          .filter(day => new Date(day) > new Date(finalDay))
          .sort(dayDistanceSortFunc)

        const rightDirection = offset > 0
        finalDay = rightDirection ? mostCloseRightDays[0] : mostCloseLeftDays[0]
      }

      this.resetValue()
      this.currentDay = finalDay
    },
    isDayAvailable(day) {
      return Object.keys(this.slots).includes(day)
    },
    isDayAvailableByOffset(offset) {
      return this.isDayAvailable(this.getOffsetDay(offset))
    },
    getDisplayDay(offset) {
      const date = new Date(this.getOffsetDay(offset))
      return date.toLocaleString(this.$t('datetimeFormat'), {
        day: '2-digit',
      })
    },
    getDisplayWeekday(offset) {
      const date = new Date(this.getOffsetDay(offset))
      return date.toLocaleString(this.$t('datetimeFormat'), {
        weekday: 'short',
      })
    },
    isWeekend(offset) {
      const date = new Date(this.getOffsetDay(offset))
      return [0, 6].includes(date.getDay())
    },
    getSelectedSlots(slotsRange) {
      if (!slotsRange.startTime && !slotsRange.endTime) return null

      const { slots } = this.attribute
      //все слоты в затрагиваемых днях, включает в т.ч. невыбранные слоты
      const slotsInSelectedDays = []
      let checkDay = new Date(formatDate(slotsRange.startTime))
      while (checkDay < slotsRange.endTime) {
        slotsInSelectedDays.push(...slots[formatDate(checkDay)])
        checkDay.setDate(checkDay.getDate() + 1)
      }

      //фильтруем список от невыбранных слотов
      const selectedSlots = slotsInSelectedDays.filter(checkSlot => {
        return (
          checkSlot.startTime >= slotsRange.startTime &&
          checkSlot.endTime <= slotsRange.endTime
        )
      })

      return selectedSlots
    },
    isSlotsRangeForbidden(slotsRange) {
      if (!slotsRange.startTime && !slotsRange.endTime) return null
      const selectedSlots = this.getSelectedSlots(slotsRange)

      //проверяем содержат ли выбранные слоты запрещенный слот
      const hasForbidden = !!selectedSlots.find(slot => slot.forbidden)
      return hasForbidden
    },
    /**
     * проверяет возможно ли такое значение аттрибута
     * @param {Date} date
     * @returns {Date} validatedDate
     */
    validateNewValue(slotsRange) {
      slotsRange.startTime?.setSeconds(0)
      slotsRange.startTime?.setMilliseconds(0)
      slotsRange.endTime?.setSeconds(0)
      slotsRange.endTime?.setMilliseconds(0)
      if (!slotsRange.startTime && !slotsRange.endTime) return slotsRange

      const isForbidden = this.isSlotsRangeForbidden(slotsRange)
      if (isForbidden) {
        slotsRange.startTime = slotsRange.endTime = null
      }

      return slotsRange
    },
    resetValue() {
      this.setValue({ startTime: null, endTime: null })
    },
    setValue(value) {
      const day = formatDate(value?.startTime)
      if (this.availableDays.includes(day)) this.currentDay = day

      this.$attr.value = this.validateNewValue(value)
      this.$events.emit('attr-value-changed', this.attribute.id, value)
    },
    $setValueHandler(value) {
      if (value instanceof Date) {
        const day = formatDate(value)
        if (this.availableDays.includes(day)) this.currentDay = day
        return
      }

      const handle = value => {
        if (typeof value == 'string' && String(Number(value)) == value)
          value = Number(value)
        value = new Date(value)
        return value
      }
      this.setValue({
        startTime: handle(value.startTime),
        endTime: handle(value.endTime),
      })
    },
    $getValue() {
      return {
        startTime: this.$attr.value.startTime
          ? new Date(this.$attr.value.startTime.normalize()).getTime()
          : null,
        endTime: this.$attr.value.endTime
          ? new Date(this.$attr.value.endTime.normalize()).getTime()
          : null,
      }
    },
  },
  computed: {
    displayValue() {
      const { startTime, endTime } = this.$attr.value
      if (!startTime || !endTime) return null
      const sameDay = formatDate(startTime) == formatDate(endTime)

      const startTimeStr = startTime.toLocaleString(this.$t('datetimeFormat'), {
        timeStyle: 'short',
        dateStyle: 'short',
      })
      let endTimeStr = endTime.toLocaleString(this.$t('datetimeFormat'), {
        timeStyle: 'short',
        dateStyle: 'short',
      })
      if (sameDay) {
        endTimeStr = endTime.toLocaleTimeString(this.$t('datetimeFormat'), {
          timeStyle: 'short',
        })
      }

      return [startTimeStr, endTimeStr].join(' - ')
    },
    slots() {
      return this.attribute.slots
    },
    availableDays() {
      return Object.keys(this.slots).sort()
    },
    dayMonthTitle() {
      const date = new Date(this.currentDay)
      return (
        date.toLocaleString(this.$t('datetimeFormat'), {
          month: 'long',
        }) +
        ' ' +
        date.getFullYear()
      )
    },
  },
}
</script>

<style lang="scss">
.slots-attribute {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > .box {
    margin-top: 5px;
    cursor: pointer;
    border: 1px #ccc solid;
    box-shadow: 0px 2px 4px 0px rgba(50, 50, 50, 0.4);
    border-radius: 8px;
    padding: 3px 6px;
    display: flex;
    align-items: center;

    > .md-icon {
      margin-right: 5px;
      -webkit-text-fill-color: rgb(82, 82, 82) !important;
    }
  }

  > .popup-base > .popup {
    > .month-selector {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;

      > .title {
        min-width: 150px;
        text-align: center;
      }
      > .arrow {
        cursor: pointer;
      }
    }
    > .day-selector {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      box-shadow: 0px 2px 4px 0px rgba(50, 50, 50, 0.4);

      > .day {
        cursor: pointer;
        padding: 4px 12px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &.active {
          cursor: default;
          font-size: 19px;
          > .day {
            font-weight: 500 !important;
          }
        }
        &.disabled {
          cursor: default;
          opacity: 0.5;
        }

        > .day {
          font-weight: 400 !important;
        }

        > .weekday {
          &.weekend {
            -webkit-text-fill-color: red;
            color: red;
          }
        }
      }

      > .arrow {
        cursor: pointer;
      }
    }
    > .slots {
      padding-top: 15px;
      max-height: 300px;
      overflow: hidden;
      overflow-y: scroll;
      white-space: nowrap;
      touch-action: pan-y;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;

      > .group {
        margin-bottom: 15px;

        > .title {
          font-weight: 500;
          margin-bottom: 5px;
        }

        > .slots {
          max-width: 320px;
          display: flex;
          flex-wrap: wrap;

          > .slot {
            margin: 5px;
            cursor: pointer;
            padding: 2px 4px;
            border: 1px #ccc solid;
            border-radius: 8px;
            margin-bottom: 5px;
            text-align: center;
            transition: 0.25s all;

            &.active {
              background-color: #349fe7;
              -webkit-text-fill-color: white;
              border-color: transparent;
            }

            &.forbidden {
              cursor: default;
              -webkit-text-fill-color: #ccc;
            }
          }
        }
      }
    }
    > .button-wrapper {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      > .button {
        background-color: #a3a3a3;
        -webkit-text-fill-color: white !important;
        padding: 6px 16px;
        margin-left: 15px;
        border-radius: 8px;
        transition: 0.25s all;

        &.active {
          background-color: #349fe7;
          cursor: pointer;
        }

        &:hover {
          opacity: 0.85;
        }
      }

      > .info {
        max-width: 240px;
        > .error {
          color: red;
          -webkit-text-fill-color: red;
        }
      }
    }
  }
}
</style>
