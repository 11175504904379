<template>
  <div id="attributes-popup" v-show="show">
    <div class="blackout" @click="show = false"></div>
    <div class="popup">
      <p class="title">{{ $t('order.selectAdditional') }}</p>

      <div
        class="scroll-fade top"
        @click="scrollTop"
        :class="{ show: showFadeTop }"
      >
        <md-icon>expand_less</md-icon>
      </div>
      <div class="scrollable" ref="scrollable">
        <AttributeWrapper
          v-for="attribute in attributes"
          :key="attribute.id"
          :attribute="attribute"
          :noMargin="true"
        />
      </div>
      <div
        class="scroll-fade btm"
        @click="scrollBottom"
        :class="{ show: showFadeBtm }"
      >
        <md-icon>expand_more</md-icon>
      </div>

      <div class="close-btn" @click="show = false">
        {{ $t('materialUI.confirm') }}
      </div>
    </div>
  </div>
</template>

<script>
import AttributeWrapper from '@/components/Attributes/AttributeWrapper'

export default {
  components: { AttributeWrapper },
  data() {
    return {
      show: false,
      attributes: [],

      showFadeTop: true,
      showFadeBtm: true,
    }
  },
  mounted() {
    this.$refs.scrollable.addEventListener('scroll', this.updateFade)
  },
  beforeDestroy() {
    this.$refs.scrollable.removeEventListener('scroll', this.updateFade)
  },
  events: {
    'show-attributes'(attributes) {
      this.attributes = attributes
      this.updateFade()
      this.show = true

      let updateInt = setInterval(this.updateFade, 100)
      setTimeout(() => {
        clearInterval(updateInt)
      }, 2500)
    },
  },
  methods: {
    updateFade() {
      let el = this.$refs.scrollable
      if (!el) return
      let maxScrollTop = el.scrollHeight - el.clientHeight

      this.showFadeTop = el.scrollTop > 10
      this.showFadeBtm = maxScrollTop - el.scrollTop > 10
    },
    scrollTop() {
      window.smoothScroll({
        scrollingElement: this.$refs.scrollable,
        yPos: this.$refs.scrollable.scrollTop - 200,
      })
    },
    scrollBottom() {
      window.smoothScroll({
        scrollingElement: this.$refs.scrollable,
        yPos: this.$refs.scrollable.scrollTop + 200,
      })
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        if (!value) this.attributes = []
        this.$store.navigationBlockers.attributesPopup = value
          ? () => {
              this.$nextTick(() => {
                this.show = false
              })
            }
          : null
      },
    },
  },
}
</script>

<style lang="scss">
#attributes-popup {
  .blackout {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 16;
  }

  .popup {
    z-index: 16;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 1px 11px 1px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    padding: 15px 25px 15px 25px;
    max-height: 97%;
    width: max-content;
    display: flex;
    flex-direction: column;

    > .scrollable {
      overflow: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      margin-bottom: 15px;
      padding: 10px 0 10px 15px;
    }

    > .title {
      font-size: 18px;
      font-weight: 500;
    }

    > .close-btn {
      text-transform: uppercase;
      cursor: pointer;
      align-self: flex-end;
      font-weight: 500;
      padding: 5px 15px 5px 15px;
      transition: 0.3s all;
      border-radius: 5px;
      background-color: rgb(255, 255, 255);

      &:hover {
        background-color: rgb(221, 221, 221);
      }
    }

    > .scroll-fade {
      position: absolute;
      left: 0;
      right: 0;
      height: 55px;
      transition: 0.3s all;
      opacity: 0;
      cursor: pointer;
      pointer-events: none;
      z-index: 1;

      &.show {
        opacity: 1;
        pointer-events: initial;
      }

      .md-icon {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        font-size: 36px !important;
      }

      &.top {
        background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
        top: 50px;

        .md-icon {
          top: 0;
        }
      }
      &.btm {
        background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
        bottom: 50px;

        .md-icon {
          bottom: 0;
        }
      }
    }
  }
}
</style>
