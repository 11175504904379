<script>
import * as rax from 'retry-axios'

export default {
  created() {
    this.$api.defaults.headers.common.Locale = window.userLocale
    this.$api.defaults.headers.common['x-customer-id'] = localStorage.customerId

    const authUsername = process.env.VUE_APP_AUTH_USERNAME
    const authPassword = process.env.VUE_APP_AUTH_PASSWORD
    if (authUsername && authPassword) {
      this.$api.defaults.auth = {
        username: authUsername,
        password: authPassword,
      }
    }

    this.initAuthInterceptor()
    this.initRetryInterceptor()
    this.checkRefreshToken()
    this.authorize()
  },
  methods: {
    initAuthInterceptor() {
      const headers = this.$api.defaults.headers.common

      this.$api.interceptors.response.use(null, err => {
        const checkErrorCode = code => this.$store.checkErrorCode(err, code)
        const authed = this.$store.isAuthed

        if (checkErrorCode(401) && this.$store.overrideToken)
          this.$events.emit(
            'show-alert',
            this.$t('errors.error'),
            String(err.response?.data?.message),
          )

        if (checkErrorCode(401) && localStorage.token) this.updateToken(err)

        if (checkErrorCode(401) && !authed) {
          this.$events.emit('do-login-popup')
          this.$events.emit('authorized')
        }

        if (checkErrorCode(401) && !authed && headers['x-customer-id']) {
          localStorage.removeItem('customerId')
          this.$store.overrideCustomerId = null
          this.$events.emit('authorized')
          console.log(
            'removed customerId(current and override) cuz of 401 error',
          )
        }

        if (checkErrorCode(403))
          this.$events.emit('do-login-popup', { noConfirm: true })

        console.log('Api Error', JSON.parse(JSON.stringify(err)), {
          url: err.config.url,
          authed: authed,
          token: err.config.headers.Token,
          customerId: err.config.headers['x-customer-id'],
          overrideToken: this.$store.overrideToken,
          overrideCustomerId: this.$store.overrideCustomerId,
          storage: {
            token: localStorage.token,
            customerId: localStorage.customerId,
          },
        })
        if (!this.$store.isLoginError(err) && err.response?.data?.message)
          this.$events.emit('log-event', 'popup', err.response?.data?.message)
        return Promise.reject(err)
      })
    },
    checkRefreshToken() {
      if (localStorage.refreshToken && !localStorage.token) {
        console.log(
          'unabled to find token, but we have refresh token, updating',
        )
        this.$api
          .get('login/refresh?token=' + localStorage.refreshToken)
          .then(res => {
            localStorage.token = res.data.data.token
            localStorage.refreshToken = res.data.data.refreshToken
            this.$api.defaults.headers.common.Token = localStorage.token
            this.$events.emit('authorized')
            console.log('token updated')
          })
          .catch(err => {
            if (err.response.status == 400) {
              console.log('unable to update token, removing refresh token')
              localStorage.removeItem('refreshToken')
            }
          })
      }
    },
    initRetryInterceptor() {
      this.$api.defaults.raxConfig = {
        instance: this.$api,
        statusCodesToRetry: [[500, 599]],
        shouldRetry: err => {
          return rax.shouldRetryRequest(err) && err.config.retry
        },
      }
      rax.attach(this.$api)
    },
    updateToken(err) {
      console.log(
        'trying to update token cuz 401 error for',
        '/' + err.config.url,
        JSON.parse(JSON.stringify(err)),
      )

      if (err.config.skip401) {
        console.log('ignoring error cuz skip401 config')
        return Promise.reject(err)
      }

      console.log('trying to update token')
      this.updatingToken = true
      const updateToken = () => {
        return this.$externalApi.get(
          this.$api.defaults.baseURL +
            'login/refresh?token=' +
            localStorage.refreshToken,
        )
      }
      return updateToken()
        .then(res => {
          localStorage.token = res.data.data.token
          localStorage.refreshToken = res.data.data.refreshToken
          this.$api.defaults.headers.common.Token = localStorage.token
          this.$events.emit('authorized')
          console.log('token updated, retrying request')
          return this.$api.request({
            ...err.config,
            headers: { Token: this.$api.defaults.headers.common.Token },
            skip401: true,
          })
        })
        .catch(err => {
          if (!err.config.skip401) {
            console.log('failed to update token, reauthorizing')
            this.$store.user = null
            localStorage.removeItem('token')
            localStorage.removeItem('refreshToken')
            delete this.$api.defaults.headers.common.Token
            this.$events.emit('authorized')
            this.$events.emit('do-login-popup')
          }
          return Promise.reject(err)
        })
    },
    authorize() {
      const headers = this.$api.defaults.headers.common

      if (localStorage.token) headers.Token = localStorage.token
      else delete headers.Token

      headers['x-customer-id'] = localStorage.customerId
      if (!localStorage.customerId) delete headers['x-customer-id']

      if (this.$store.overrideToken) {
        headers.Token = this.$store.overrideToken
      }

      if (this.$store.overrideCustomerId) {
        headers['x-customer-id'] = this.$store.overrideCustomerId
      }

      if (
        headers.Token &&
        headers.Token == localStorage.token &&
        headers['x-customer-id']
      )
        delete headers['token']

      this.$store.isAuthed = !!headers.Token

      const needToFetchUser = () => {
        return (
          this.$store.isAuthed ||
          localStorage.customerId ||
          this.$store.overrideCustomerId
        )
      }

      if (needToFetchUser()) {
        this.$api.get(`user`).then(res => {
          if (!needToFetchUser()) return
          this.$store.user = res.data.data
          if (res.data.data.prefferedLanguageCode)
            localStorage.lang = res.data.data.prefferedLanguageCode
        })
      }
    },
  },
}
</script>
