<script>
export default {
  created() {
    this.$router.beforeEach((to, from, next) => {
      if (
        !(from.path == '/' && this.$store.isIframe) &&
        this.$store.routerHistory.at(-1)?.fullPath != from.fullPath
      )
        this.$store.routerHistory.push(from)

      //save query
      const queryToSave = ['customerId']
      const fromQueryFiltered = Object.fromEntries(
        Object.entries(from.query).filter(
          ([key]) =>
            queryToSave.includes(key) && !Object.keys(to.query).includes(key),
        ),
      )

      if (Object.keys(fromQueryFiltered).length > 0) {
        const newQuery = { ...to.query, ...fromQueryFiltered }
        next({
          ...to,
          query: newQuery,
        })
      } else next()
    })
  },
}
</script>
