export default {
  data() {
    return {
      overrideToken: null,
      overrideCustomerId: null,
      isAuthed: false,

      isFull: window.innerWidth >= 730,
      routerWidth: 430,
      scrollY: 0,
      user: null,

      sidebarOpened: false,
      routerHistory: [],

      roomBreakfastAddingToFinal: false,

      predefinedAttrValuesByType: {},
      lockedAttrValues: {},
      attributesSaves: {},
      bookingSaves: {},
      offerSaves: {},

      placeCategoriesWithClosedHint: [],
      placeCategoriesTimeSaves: {},

      iframeRoot: null,
      pwaPrompt: null,

      navigationBlockers: {
        imagePopup: null,
        loginPopup: null,
        confirmPopup: null,
        attributesPopup: null,
        alertPopup: null,
        contractPopup: null,
      },

      hotelsSalesPopupShowed: false,
      utmSent: false,

      processedQueryAuth: false,

      hotelYa: null,

      isSystemDarkTheme: window.matchMedia('(prefers-color-scheme: dark)')
        .matches,
    }
  },
  mounted() {
    this._resize()
  },
  methods: {
    setOverrideToken(token) {
      this.overrideToken = token
      this.$events.emit('authorized')
    },
    setOverrideCustomerId(customerId) {
      this.overrideCustomerId = customerId
      this.$events.emit('authorized')
    },
    checkErrorCode(err, code) {
      if (String(err.message).includes(String(code))) return true
      if (err.status == code) return true
      if (err.response?.status == code) return true

      return false
    },
    isLoginError(err) {
      return this.checkErrorCode(err, 401) || this.checkErrorCode(err, 403)
    },
    getQuery() {
      const urlParams = new URLSearchParams(window.location.search)
      const paramsObject = {}

      for (const [key, value] of urlParams.entries()) {
        paramsObject[key] = value
      }

      return paramsObject
    },
    async sendUTMIfNotAlready(utm) {
      if (this.utmSent) return
      await this.$api.post('user/utm', utm)
      this.utmSent = true
    },
    clearNavigationBlockers() {
      for (const blockerKey of Object.keys(this.navigationBlockers)) {
        this.navigationBlockers[blockerKey] = null
      }
    },
    _resize() {
      this.isFull = window.innerWidth > 730
    },
    _darkTheme({ matches }) {
      this.isSystemDarkTheme = matches
    },
  },
  computed: {
    /**
     * @returns {'android'|'ios'|'pc'|'mac'}
     */
    device() {
      const ua = navigator.userAgent
      const checks = {
        android: 'android',
        iphone: 'ios',
        ipad: 'ios',
        ipod: 'ios',
        mac: 'mac',
      }
      for (const key of Object.keys(checks)) {
        if (ua.toLowerCase().includes(key.toLowerCase())) return checks[key]
      }
      return 'pc'
    },
    isIframe() {
      return window !== window.parent
    },
    isPwa() {
      return (
        (window.matchMedia('(display-mode: standalone)').matches ||
          navigator.standalone) ??
        false
      )
    },
  },
}
