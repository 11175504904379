<script>
import Vue from 'vue'

export default {
  created() {
    if (!this.attribute) {
      console.log('cant get attribute prop in attribute mixin')
      return
    }

    const saves = this.$store.attributesSaves

    const attrId = this.attribute.id
    const defaultValue =
      this.$store.lockedAttrValues[attrId] ??
      this.$store.predefinedAttrValuesByType[this.attribute['@type']] ??
      this.attribute.defaultValue ??
      this.defaultValue ??
      null

    if (!saves[attrId])
      saves[attrId] = {
        value: defaultValue,
        defaultValue,
        isLocked: () =>
          Object.keys(this.$store.lockedAttrValues).includes(attrId),
      }

    saves[attrId].setValue =
      this.$setValueHandler ??
      (value => {
        saves[attrId].value = value
      })

    saves[attrId].getValue =
      this.$getValue ??
      (() => {
        return saves[attrId].value
      })

    saves[attrId].anyComponentIsExist = true

    this.$attr = Vue.observable(saves[attrId])

    this.$events.emit('attr-value-changed', attrId, this.$attr.value)
    if (!this.$manualSetEventImplementation)
      this.$destroyAttrValueWatcher = this.$watch(
        () => this.$attr.value,
        value => {
          if (this.$watchValue) this.$watchValue(value)
          this.$events.emit('attr-value-changed', attrId, value)
        },
        { deep: true },
      )
  },
  destroyed() {
    const saves = this.$store.attributesSaves
    const attrId = this.attribute.id

    if (saves[attrId]) saves[attrId].anyComponentIsExist = false
    if (this.$destroyAttrValueWatcher) this.$destroyAttrValueWatcher()
  },
}
</script>
