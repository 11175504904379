class FakeStorage {
  data = {}

  getItem(prop) {
    if (this.data[prop] !== void 0) {
      return this.data[prop]
    } else {
      return null
    }
  }
  setItem(prop, value) {
    if (arguments.length === 1) {
      throw new Error(
        "Uncaught TypeError: Failed to execute 'setItem' on 'Storage': 2 arguments required, but only 1 present.",
      )
    }
    switch (value) {
      case void 0:
        value = 'undefined'
        break
      case null:
        value = 'null'
        break
      default:
        value = value.toString()
    }
    if (this.data[prop] === value) return
    this.data[prop] = value
  }
  removeItem(prop) {
    if (this.data[prop]) return delete this.data[prop]
  }
  clear() {
    for (const name of Object.keys(this.data)) delete this.data[name]
  }

  getProxy() {
    const storage = this
    return new Proxy(storage.data, {
      set(obj, prop, value) {
        switch (prop) {
          case 'length':
          case 'getItem':
          case 'setItem':
          case 'removeItem':
            break
          default:
            storage.setItem(prop, value)
            return true
        }
      },
      get(obj, prop) {
        let v
        switch (prop) {
          case 'length':
            return Object.keys(storage.data).length
          case 'getItem':
            return storage.getItem.bind(storage)
          case 'setItem':
            return storage.setItem.bind(storage)
          case 'removeItem':
            return storage.removeItem.bind(storage)
          case 'clear':
            return storage.clear.bind(storage)
          default:
            v = storage.getItem(prop)
            if (v !== null) {
              return v
            } else {
              return void 0
            }
        }
      },
    })
  }
}

try {
  localStorage || sessionStorage
} catch (e) {
  const localStorage = new FakeStorage()
  const sessionStorage = new FakeStorage()

  console.log('Using fake storages:', { localStorage, sessionStorage })

  Object.defineProperty(window, 'localStorage', {
    get() {
      return localStorage.getProxy()
    },
  })
  Object.defineProperty(window, 'sessionStorage', {
    get() {
      return sessionStorage.getProxy()
    },
  })
}
