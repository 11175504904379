<template>
  <div class="list-attribute">
    <md-field>
      <label for="select">{{ attribute.title }}</label>
      <md-select v-model="$attr.value" name="select" :required="true">
        <md-option
          v-for="option in attribute.values"
          :key="Object.keys(option)[0]"
          :value="Object.keys(option)[0]"
          >{{ option[Object.keys(option)[0]] }}</md-option
        >
      </md-select>
    </md-field>
    <div class="subtitle">{{ $stripHtml(attribute.subTitle) }}</div>
  </div>
</template>

<script>
import AttributeMixin from '@/mixins/Attribute'

export default {
  props: ['attribute'],
  mixins: [AttributeMixin],
  methods: {
    $setValueHandler(value) {
      for (const option of this.attribute.values) {
        const optionKey = Object.keys(option)[0]
        const optionValue = option[optionKey]

        if (value == optionKey || value == optionValue) {
          this.$attr.value = optionKey
          return
        }
      }
    },
  },
}
</script>

<style lang="scss">
.list-attribute {
  .md-field {
    margin-bottom: 10px;
  }
}
</style>
