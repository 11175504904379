<script>
let _windowEvents = ['scroll', 'resize']
let darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

export default {
  created() {
    _windowEvents.forEach(event => {
      if (this['_' + event]) window.addEventListener(event, this['_' + event])
    })

    if (this._darkTheme) {
      darkThemeMediaQuery.addEventListener('change', this._darkTheme)
      this._darkTheme(darkThemeMediaQuery)
    }
  },
  destroyed() {
    _windowEvents.forEach(event => {
      if (this['_' + event])
        window.removeEventListener(event, this['_' + event])
    })

    if (this._darkTheme) {
      darkThemeMediaQuery.removeEventListener('change', this._darkTheme)
    }
  },
}
</script>
