var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"slots-attribute"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.attribute.title))]),_c('div',{staticClass:"box",on:{"click":function($event){_vm.popupActive = !_vm.popupActive}}},[_c('md-icon',[_vm._v("event")]),(!_vm.$attr.value.startTime || !_vm.$attr.value.endTime)?_c('span',[_vm._v(_vm._s(_vm.attribute.subTitle ? _vm.$stripHtml(_vm.attribute.subTitle) : _vm.$t('choose.notSelected')))]):_c('span',[_vm._v(" "+_vm._s(_vm.displayValue)+" ")])],1),_c('PopupBase',{attrs:{"show":_vm.popupActive,"title":_vm.attribute.title},on:{"close":function($event){_vm.popupActive = false}}},[_c('div',{staticClass:"month-selector"},[_c('div',{staticClass:"arrow",on:{"click":function($event){return _vm.selectDay(-30)}}},[_c('md-icon',[_vm._v("arrow_left")])],1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.dayMonthTitle))]),_c('div',{staticClass:"arrow",on:{"click":function($event){return _vm.selectDay(+30)}}},[_c('md-icon',[_vm._v("arrow_right")])],1)]),_c('div',{staticClass:"day-selector"},[_c('div',{staticClass:"arrow",on:{"click":function($event){return _vm.selectDay(-1)}}},[_c('md-icon',[_vm._v("arrow_left")])],1),_vm._l(([-2, -1, 0, 1, 2]),function(offset){return _c('div',{key:offset,staticClass:"day",class:{
          active: offset == 0,
          disabled: !_vm.isDayAvailableByOffset(offset),
        },on:{"click":function($event){return _vm.selectDay(offset)}}},[_c('div',{staticClass:"day"},[_vm._v(" "+_vm._s(_vm.getDisplayDay(offset))+" ")]),_c('div',{staticClass:"weekday",class:{
            weekend: _vm.isWeekend(offset),
          }},[_vm._v(" "+_vm._s(_vm.getDisplayWeekday(offset))+" ")])])}),_c('div',{staticClass:"arrow",on:{"click":function($event){return _vm.selectDay(1)}}},[_c('md-icon',[_vm._v("arrow_right")])],1)],2),_c('div',{staticClass:"slots"},_vm._l((_vm.getSlotsGroupsForDay(_vm.currentDay)),function(group){return _c('div',{key:group.title,staticClass:"group"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(group.title))]),_c('div',{staticClass:"slots"},_vm._l((group.slots),function(slot){return _c('div',{key:slot.id,staticClass:"slot",class:{
              active: _vm.isSlotActive(slot),
              forbidden: slot.forbidden,
            },on:{"click":function($event){return _vm.selectSlot(slot)}}},[_vm._v(" "+_vm._s(slot.id)+" ")])}),0)])}),0),_c('div',{staticClass:"button-wrapper"},[_c('div',{staticClass:"info"},[(!(_vm.$attr.value.startTime && _vm.$attr.value.endTime))?_c('div',[_vm._v(" "+_vm._s(_vm.$t('slots.chooseSlots'))+" ")]):_c('div',[_vm._v(_vm._s(_vm.displayValue))])]),_c('div',{staticClass:"button",class:{ active: _vm.$attr.value.startTime && _vm.$attr.value.endTime },on:{"click":function($event){_vm.popupActive = false}}},[_vm._v(" "+_vm._s(_vm.$t('materialUI.confirm'))+" ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }