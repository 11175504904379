import Vue from 'vue'
import VueRouter from 'vue-router'

//ignore router redirected error
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  const NavigationFailureType = {
    redirected: 2,
    aborted: 4,
    cancelled: 8,
    duplicated: 16,
  }

  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => {
    if (VueRouter.isNavigationFailure(err, NavigationFailureType.redirected)) {
      return err
    }
    return Promise.reject(err)
  })
}

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    view: 'Home',
    children: [
      {
        path: '/hotel/:id/:tab?',
        view: 'Hotel',
      },
      {
        path: '/hotel/:id/open/:room',
        view: 'OpenRoom',
      },
      {
        path: '/excursions/:id',
        view: 'Excursions',
      },
      {
        path: '/category/:id/:stay?',
        view: 'Services',
      },
      {
        path: '/:orderId?',
        view: 'Order',
      },
      {
        path: '/payment/success',
        view: 'Order',
      },
      {
        path: '/payment/fail',
        view: 'Order',
      },
      {
        path: '/service/:id',
        view: 'Service',
      },
      {
        path: 'chat/:id',
        view: 'User/MessagesChat',
      },
      {
        path: '/orderdetails/:id',
        view: 'OrderDetails',
      },
    ],
  },

  {
    path: '/settings',
    view: 'Settings/SettingsBase',
    redirect: '/settings/profile',
    children: [
      {
        path: 'profile',
        view: 'Settings/Profile',
      },
      {
        path: 'fellowtravelers',
        view: 'Settings/FellowTravelers',
      },
      {
        path: 'fellowtravelers/add',
        view: 'Settings/FellowTravelersAdd',
      },
      {
        path: 'fellowtravelers/edit/:id',
        view: 'Settings/FellowTravelersEdit',
      },
      {
        path: 'notifications',
        view: 'Settings/Notifications',
      },
      {
        path: 'language',
        view: 'Settings/Language',
      },
    ],
  },

  {
    path: '/user',
    view: 'User/UserBase',
    redirect: '/user/orders',
    children: [
      {
        path: 'orders',
        view: 'User/Orders',
      },
      {
        path: 'stays',
        view: 'User/Stays',
      },
      {
        path: 'messages',
        view: 'User/Messages',
      },
    ],
  },

  {
    path: '/sign/:token',
    view: 'ContractSign',
  },

  { path: '*', redirect: '/' },
]

function processRoute(route) {
  if (typeof route == 'string') {
    routes.push({ view: route })
    routes = routes.filter(item => item != route)
    route = routes[routes.length - 1]
  }
  route.component = () => import('@/views/' + route.view + '.vue')
  route.name = route.view
  if (typeof route.path !== 'string')
    route.path = '/' + route.view.toLowerCase()
  if (route.path.includes(':') || route.path.includes('?')) route.props = true
  if (route.childrens) {
    route.children = route.childrens
    delete route.childrens
  }
  if (route.children) route.children.forEach(processRoute)
}
routes.forEach(processRoute)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
