<template>
  <div class="qty-attribute">
    <div class="info">
      <p class="label">{{ attribute.title }}</p>
      <div class="subtitle" v-if="attribute.subTitle">
        {{ $stripHtml(attribute.subTitle) }}
      </div>
    </div>
    <div class="box" :class="{ hideBorder: !isEditable }">
      <div
        class="button"
        v-if="isEditable"
        @click="$attr.value > min ? ($attr.value -= step) : ''"
      >
        <md-icon>remove</md-icon>
      </div>
      <div class="count">
        {{ displayValue }}
      </div>
      <div
        class="button"
        v-if="isEditable"
        @click="$attr.value < max ? ($attr.value += step) : ''"
      >
        <md-icon>add</md-icon>
      </div>
    </div>
  </div>
</template>

<script>
import AttributeMixin from '@/mixins/Attribute'
import formatTimeDisplay from '../..//lib/formatTimeDisplay'

export default {
  props: ['attribute'],
  mixins: [AttributeMixin],
  data() {
    return {
      defaultValue:
        Number(this.attribute.defaultValue) || this.attribute.min || 0,
    }
  },
  methods: {
    $setValueHandler(value) {
      this.$attr.value = Number(value)
    },
  },
  computed: {
    min() {
      return this.attribute.min || 0
    },
    max() {
      return Number(this.attribute.max || 0) || Number.MAX_VALUE
    },
    step() {
      return this.attribute.increment ?? 1
    },
    isEditable() {
      const { min, max } = this
      const { value } = this.$attr
      return value > min || value < max
    },
    displayValue() {
      return formatTimeDisplay(this.$attr.value, this.attribute.unit, key =>
        this.$t(key),
      )
    },
  },
}
</script>

<style lang="scss">
.qty-attribute {
  width: 100%;
  margin: 3px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  > .info {
    .label {
      margin: 0;
    }
    > .subtitle {
      padding-right: 10px;
      font-size: 12px;
      line-height: 1;
    }
  }

  > .box {
    border: 1px #ccc solid;
    border-radius: 15px;

    display: flex;
    align-items: center;

    &.hideBorder {
      border: none;
    }

    .button {
      cursor: pointer;
      transition: 0.15s all;
      padding: 5px 10px;

      &:hover {
        filter: brightness(1.2);
      }
    }
    .count {
      width: max-content;
      line-height: 24px;
      text-align: center;
    }
  }
}
</style>
