<script>
export default {
  craeted() {
    let css = this.insertCSS(`.md-icon {visibility: hidden}`)
    let interval = setInterval(() => {
      let loaded = document.fonts.check('12px Material Icons')
      if (loaded) {
        clearInterval(interval)
        css.outerHTML = ''
      }
    }, 500)
  },
  methods: {
    insertCSS(styles) {
      let styleSheet = document.createElement('style')
      styleSheet.type = 'text/css'
      styleSheet.innerText = styles
      document.head.appendChild(styleSheet)
      return styleSheet
    },
  },
}
</script>
