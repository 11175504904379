<template>
  <div id="alert-popup">
    <md-dialog-alert
      :md-active.sync="show"
      :md-title="title"
      :md-content="description"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      title: '',
      description: '',
    }
  },
  events: {
    'show-alert'(title, description) {
      this.title = title
      this.description = description
        .replace(/\r\n/g, '<br>')
        .replace(/\r\n/g, '<br>')
        .replace(/\n/g, '<br>')
        .replace(/\r/g, '<br>')
      this.show = true
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        this.$store.navigationBlockers.alertPopup = value
          ? () => {
              this.$nextTick(() => {
                this.show = false
              })
            }
          : null
      },
    },
  },
}
</script>
