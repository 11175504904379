<template>
  <div class="date-attribute">
    <md-datepicker
      :value="$attr.value"
      @input="handleDatepickerInput"
      :md-disabled-dates="getIsDateDisabled"
      :md-clearable="false"
      :md-disabled-input="true"
    >
      <label>{{
        $attr.value ? attribute.title : $t('choose.notSelected')
      }}</label>
      <div class="underlabel">
        <span class="label">{{ $stripHtml(attribute.subTitle) }}</span>
      </div>
    </md-datepicker>
  </div>
</template>

<script>
import AttributeMixin from '@/mixins/Attribute'
import { formatDate } from '../../lib/formatDate'

export default {
  props: ['attribute'],
  mixins: [AttributeMixin],
  data() {
    return {
      defaultValue: new Date(),
      $manualSetEventImplementation: true,
      range: {},
    }
  },
  created() {
    this.updateRange()
    this.watchRef()
  },
  methods: {
    updateRange() {
      //У range приходящего с сервера при обращении к Object.keys ключи могут быть неотсортированы
      this.range = this.attribute.range
      this.setValue(this.$attr.value)
    },
    /**
     * @param {Date} date
     */
    getIsDateDisabled(date) {
      const availableDays = Object.keys(this.range)
      return !availableDays.includes(formatDate(date))
    },
    handleDatepickerInput(e) {
      const currentDate = this.$attr.value
      const newDate = new Date(e)
      newDate.setHours(currentDate.getHours())
      newDate.setMinutes(currentDate.getMinutes())
      newDate.setSeconds(currentDate.getSeconds())
      newDate.setMilliseconds(currentDate.getMilliseconds())

      if (newDate.getTime() != currentDate.getTime()) this.setValue(newDate)
    },
    /**
     * проверяет возможна ли такая дата, если нет, то исправляет на первую доступную
     * @param {Date} date
     * @returns {Date} validatedDate
     */
    validateNewValue(date) {
      date.setSeconds(0)
      date.setMilliseconds(0)

      //доступен ли такой день
      const availableDays = Object.keys(this.range).sort()
      if (availableDays.length == 0) {
        console.error('[DateTime Attribute] No available days')
        return date
      }

      if (!availableDays.includes(formatDate(date))) {
        date = new Date(availableDays[0])
      }

      //доступен ли такой час
      const availableHours = Object.keys(this.range[formatDate(date)])
        .map(hour => Number(hour))
        .sort()
      if (availableHours.length == 0) {
        console.error('[DateTime Attribute] No available hours')
        return date
      }

      if (!availableHours.includes(date.getHours())) {
        date.setHours(availableHours[0])
        date.setMinutes(0)
      }

      //доступна ли такая минута
      const availableMinutes = this.range[formatDate(date)][date.getHours()]
      if (availableMinutes.length == 0) {
        console.error('[DateTime Attribute] No available minutes')
        return date
      }

      if (!availableMinutes.includes(date.getMinutes())) {
        date.setMinutes(availableMinutes[0])
      }

      return date
    },
    setValue(date) {
      this.$attr.value = this.validateNewValue(date)
      this.$events.emit('attr-value-changed', this.attribute.id, date)
    },
    $setValueHandler(value) {
      if (typeof value == 'string' && String(Number(value)) == value)
        value = Number(value)
      value = new Date(value)
      this.setValue(value)
    },
    $getValue() {
      return new Date(this.$attr.value.normalize()).getTime()
    },
    /**
     * иногда могут быть две связанные даты, например заезда и выезда
     * в дате выезда в ref содержится id даты заезда
     * нам нужно не позволить в дате выезда выбрать дату меньше даты заезда
     */
    watchRef() {
      if (!this.attribute.ref) return
      this.$watch(
        () => this.$store.attributesSaves[this.attribute.ref].value,
        value => {
          const date = new Date()
          date.setTime(value)
          date.setDate(date.getDate() + 1)

          // this.updateRangeOptions.minimumDateValue = date
        },
      )
    },
  },
}
</script>

<style lang="scss">
.date-attribute {
  .underlabel {
    position: absolute;
    font-weight: 100;
    font-size: 12px;
    width: 100%;
    margin-top: 32px;
    .label {
      display: inline-block;
    }
  }
}
</style>
