<template>
  <div id="app" :theme="theme">
    <router-view v-if="!hideRouterView && $store.processedQueryAuth" />
    <ContractPopup />
    <PwaInstallPopup />
    <LoginPopup />
    <AlertPopup />
    <ConfirmPopup />
    <AttributesPopup />
    <NotificationScreen />
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import ContractPopup from '@/components/Popups/Contract'
import PwaInstallPopup from '@/components/Popups/PwaInstall'
import LoginPopup from '@/components/Popups/Login'
import AlertPopup from '@/components/Popups/Alert'
import ConfirmPopup from '@/components/Popups/Confirm'
import AttributesPopup from '@/components/Popups/Attributes'
import NotificationScreen from '@/components/NotificationScreen'

import ApiMixin from '@/mixins/App/Api'
import PwaMixin from '@/mixins/App/Pwa'
import ThemesMixin from '@/mixins/App/Themes'
import LogsMixin from '@/mixins/App/Logs'
import IframeMixin from '@/mixins/App/Iframe'
import RedrawMixin from '@/mixins/App/Redraw'
import HeadMixin from '@/mixins/App/Head'
import MaterialMixin from '@/mixins/App/Material'
import NavBlockersMixin from '@/mixins/App/NavBlockers'
import BackHookMixin from '@/mixins/App/BackHook'
import LockedAttrsMixin from '@/mixins/App/LockedAttrs'
import CustomerIdQueryMixin from '@/mixins/App/CustomerIdQuery'

import Vue from 'vue'
import ElementUILocale from 'element-ui/lib/locale'
import { toCamelCase } from 'js-convert-case'

export default {
  name: 'App',
  components: {
    ContractPopup,
    PwaInstallPopup,
    LoginPopup,
    AlertPopup,
    ConfirmPopup,
    NotificationScreen,
    AttributesPopup,
  },
  mixins: [
    LogsMixin,
    ApiMixin,
    PwaMixin,
    NavBlockersMixin,
    IframeMixin,
    RedrawMixin,
    ThemesMixin,
    MaterialMixin,
    HeadMixin,
    BackHookMixin,
    LockedAttrsMixin,
    CustomerIdQueryMixin,
  ],
  data() {
    return {
      theme: 'default',
      hideRouterView: false,
    }
  },
  created() {
    window.$app = this
    this.processUTM()
  },
  events: {
    //Logs
    'log-event'(...args) {
      this.logEvent(...args)
    },

    //Api
    'iframe.reset-customerId'() {
      localStorage.removeItem('customerId')
      delete this.$api.defaults.headers.common['x-customer-id']
      this.$events.emit('authorized')
    },
    authorized() {
      this.authorize()
    },

    //Iframe
    'post-iframe'(type, data) {
      parent.postMessage({ type, data }, '*')
    },
    async 'iframe.open'(data) {
      if (!this.$store.iframeRoot) {
        this.$store.iframeRoot = Array.isArray(data) ? data[0] : data
      }

      const currentPathname = location.pathname

      this.$store.clearNavigationBlockers()
      if (!Array.isArray(data)) this.$router.push(data)
      else for (let route of data) await this.$router.push(route)

      if (
        currentPathname.startsWith('/payment') &&
        !this.$route.path.startsWith('/payment')
      )
        this.$router.push(currentPathname)
    },
    'iframe.set-attribute-value'({ attrId, value }) {
      const int = setInterval(() => {
        if (this.$store.attributesSaves[attrId]) {
          this.$store.attributesSaves[attrId].setValue(value)
          clearInterval(int)
        }
      }, 200)
    },
    'iframe.init-override-locale'(overrideLocale) {
      window.overrideLocale = overrideLocale
    },
    'iframe.set-locale'(locale) {
      window.userLocale = locale
      this.$i18n.locale = locale
      this.$api.defaults.headers.common.Locale = window.userLocale
    },
    'iframe.set-token'(...args) {
      this.$store.setOverrideToken(...args)
    },
    'iframe.open-chat'(hotelId) {
      if (!this.$store.isAuthed) {
        this.$events.emit('do-login-popup', {
          callback: () => this.$router.push(`/chat/${hotelId}`),
        })
      } else this.$router.push(`/chat/${hotelId}`)
    },

    //Themes
    'set-theme'(...args) {
      this.setTheme(...args)
    },
  },
  methods: {
    processUTM() {
      const query = this.$store.getQuery()
      const availableKeys = Object.keys(query).filter(q => q.startsWith('utm_'))

      const utm = {}
      for (const key of availableKeys) {
        utm[toCamelCase(key)] = query[key]
      }

      if (Object.keys(utm).length > 0) this.$store.sendUTMIfNotAlready(utm)
    },
    debugRoutes() {
      setInterval(() => {
        console.clear()
        console.table(this.$store.routerHistory)
      }, 200)
    },
  },
  watch: {
    currentLocale: {
      immediate: true,
      handler() {
        Vue.material.locale = this.$t('materialUI')
        ElementUILocale.use({ el: this.$t('elementUI') })
      },
    },
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale
    },
  },
}
</script>

<style lang="scss">
@import './scss/global-fonts.scss';

html {
  background-color: white !important;
}

html,
body {
  overscroll-behavior-y: contain;
}

.md-field {
  &::after {
    background-color: #e4e4e4 !important;
  }
}

input,
select,
textarea {
  touch-action: pan-y;
}

//viewerjs сбрасывает позицию скролла при открытии, исправляем
body.viewer-open {
  overflow: initial;
}

#MazPhoneNumberInput.vue-phone-number-input .select-country-container {
  $width: 96px;
  width: $width;
  min-width: $width;
  max-width: $width;
}

.md-checkbox:not(.md-checked).md-theme-default .md-checkbox-container {
  border-color: #757575 !important;
}

.md-datepicker-dialog,
.md-dialog-overlay {
  z-index: 20 !important;
}
.md-dialog {
  z-index: 21 !important;
}

.md-select-menu {
  max-width: 330px !important;
  z-index: 17 !important;
}

.md-checkbox .md-checkbox-label {
  height: initial !important;
}

.el-link {
  text-decoration: none !important;
}

small,
.small {
  line-height: 1.1;
}

figure {
  &.media {
    display: initial;
  }

  &.image {
    > figcaption {
      text-align: center;
      margin-top: 5px;
      color: #7a7a7a;
    }
  }

  table {
    width: 100%;
  }
}

.table {
  tbody tr {
    &:nth-of-type(odd) {
      background-color: #f2f2f2;
    }
    &:nth-of-type(even) {
      background-color: rgb(250, 250, 250);
    }
    &:hover {
      filter: brightness(0.95);
    }
  }

  thead th {
    border: none;
  }
}

blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.info-box {
  border: 1px solid #c7c4c4;
  text-align: center;
  border-radius: 10px;
  padding: 10px;
}

* {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

input {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.hue-offset {
  filter: hue-rotate(var(--hue-offset));
}

#app {
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;

  h1 {
    font-family: Oswald, Avenir, Helvetica, Arial, sans-serif;
  }
}
</style>
