<template>
  <div id="confirm-popup">
    <md-dialog-confirm
      :md-active.sync="show"
      :md-title="title"
      :md-content="description"
      :md-confirm-text="confirmText"
      :md-cancel-text="cancelText"
      @md-confirm="confirm"
      @md-cancel="cancel"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      title: this.$t('confirm.title'),
      description: '',
      confirm: () => {},
      cancel: () => {},
      confirmText: this.$t('confirm.yes'),
      cancelText: this.$t('confirm.no'),
    }
  },
  events: {
    'show-confirm'(description, confirm, cancel, options) {
      if (options == undefined) options = {}
      this.title = options.title ?? this.$t('confirm.title')
      this.description = description
        .replace(/\r\n/g, '<br>')
        .replace(/\r\n/g, '<br>')
        .replace(/\n/g, '<br>')
        .replace(/\r/g, '<br>')
      this.confirm = confirm ?? function() {}
      this.cancel = cancel ?? function() {}
      this.confirmText = options.confirmText ?? this.$t('confirm.yes')
      this.cancelText = options.cancelText ?? this.$t('confirm.no')
      this.show = true
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        this.$store.navigationBlockers.confirmPopup = value
          ? () => {
              this.$nextTick(() => {
                this.show = false
              })
            }
          : null
      },
    },
  },
}
</script>
