<template>
  <div class="input-attribute" v-if="!forcedValue">
    <md-field>
      <label>{{ attribute.title }}</label>
      <md-input
        v-model="$attr.value"
        :disabled="$attr.isLocked()"
        :type="inputType"
        :pattern="inputPattern"
        :maxlength="attrInputType == 'short_text' ? 40 : null"
      ></md-input>
    </md-field>
    <div class="description">{{ $stripHtml(attribute.subTitle) }}</div>
    <div class="error" v-if="error">{{ error }}</div>
  </div>
</template>

<script>
import AttributeMixin from '@/mixins/Attribute'
import getInputPatternByType from '@/lib/getInputPatternByType'

export default {
  props: ['attribute'],
  mixins: [AttributeMixin],
  data() {
    return {
      defaultValue: '',
    }
  },
  computed: {
    error() {
      if (this.$attr.value.length == 0) return null

      const passedPattern = new RegExp(this.inputPattern).test(this.$attr.value)
      return passedPattern
        ? null
        : this.$t('inputPatternErrors.' + this.attrInputType)
    },
    inputPattern() {
      return getInputPatternByType(this.attrInputType)
    },
    inputType() {
      let inputType = 'text'
      const typeMap = {
        email: 'email',
        integer: 'number',
      }
      if (Object.keys(typeMap).includes(this.attrInputType))
        inputType = typeMap[this.attrInputType]
      return inputType
    },
    attrInputType() {
      return this.attribute.properties?.INPUT_TYPE.toLowerCase() || 'default'
    },
    forcedValue() {
      if (!this.$store.user?.crmRelatedUser) return null

      if (this.attribute.properties?.INPUT_TYPE == 'NAME') {
        return [this.$store.user.firstName, this.$store.user.lastName]
          .filter(Boolean)
          .join(' ')
      }

      return null
    },
  },  
  watch: {
    forcedValue: {
      immediate: true,
      async handler(value) {
        await this.$waitFor(() => this.$attr)
        if (value) this.$attr.value = value
      },
    },
  },
}
</script>
<style lang="scss">
.input-attribute {
  > .md-field {
    margin: 0;
  }
  .description {
    font-weight: 300;
    font-size: 13px;
  }
  .error {
    -webkit-text-fill-color: rgb(255, 2, 2) !important;
    font-weight: 400 !important;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
</style>
