<template>
  <div id="contract-popup">
    <PopupBase
      :title="$t('contract.contractSigning')"
      :show="show"
      @close="show = false"
    >
      <div class="description">
        {{ $t('contract.contractSigningDescription') }}
      </div>
      <div class="preview" v-html="contractPreview" />
      <div class="button-wrapper">
        <el-button type="primary" round @click="sign">{{
          $t('contract.sign')
        }}</el-button>
      </div>
    </PopupBase>
  </div>
</template>

<script>
import PopupBase from '@/components/Popups/PopupBase'
export default {
  components: { PopupBase },
  data() {
    return {
      show: false,
      contractPreview: null,
      cb: null,
    }
  },
  events: {
    'show-contract'(contractPreview, cb) {
      this.contractPreview = contractPreview
      this.cb = cb || null
      this.show = true
    },
  },
  methods: {
    sign() {
      this.cb()
      this.show = false
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(value) {
        this.$store.navigationBlockers.contractPopup = value
          ? () => {
              this.$nextTick(() => {
                this.show = false
              })
            }
          : null
      },
    },
  },
}
</script>

<style lang="scss">
#contract-popup > .popup-base > .popup {
  >.description {
    padding-bottom: 5px;
    font-weight: 400;
    text-align: center;
  }
  > .preview {
    max-width: 1200px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    overscroll-behavior: none;
    padding-top: 10px;
    border-top: dashed 1px black;
    border-bottom: dashed 1px black;
  }

  > .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
}
</style>
