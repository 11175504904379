<template>
  <div class="privacy-attribute">
    <md-checkbox ref="checkbox" v-model="$attr.value" class="m-0">
      <b-link
        class="privacy"
        @click="$events.emit('show-alert', attribute.title, attribute.subTitle)"
        >{{ attribute.title }}</b-link
      >
    </md-checkbox>
  </div>
</template>

<script>
import AttributeMixin from '@/mixins/Attribute'

export default {
  props: ['attribute'],
  mixins: [AttributeMixin],
  data() {
    return {
      defaultValue: this.attribute.defaultValue ?? false,
    }
  },
  mounted() {
    this.$refs.checkbox.$el.childNodes[2].addEventListener('click', () => {
      this.$attr.value = !this.$attr.value
    })
    this.$refs.checkbox.$el.childNodes[2].style.cursor = 'default'
  },
  methods: {
    $setValueHandler(value) {
      if (value === 'true') value = true
      if (value === 'false') value = false
      this.$attr.value = Boolean(value)
    },
  },
}
</script>

<style lang="scss">
.privacy-attribute {
  a {
    -webkit-text-fill-color: var(
      --md-theme-default-primary-on-background,
      rgba(68, 138, 255, 0.8)
    );
  }
  .privacy {
    text-decoration: underline;
  }
}
</style>
