export const UNITS = {
  NUMBER: 0,
  MINUTES: 60000,
  HOURS: 3600000,
  DAYS: 86400000,
}
const UNITS_CONVERTERS = [
  { label: 'days', ms: 1000 * 60 * 60 * 24 },
  { label: 'hours', ms: 1000 * 60 * 60 },
  { label: 'minutes', ms: 1000 * 60 },
  { label: 'seconds', ms: 1000 },
]

export default function formatTimeDisplay(value, unit, $t) {
  if (!UNITS[unit]) unit = 'NUMBER'
  if (unit == 'NUMBER') return value

  if (value == 0) return '0 ' + $t('timeDisplayUnits.' + unit.toLowerCase())

  let timeMs = UNITS[unit] * value

  const portions = []
  for (const converter of UNITS_CONVERTERS) {
    const units = Math.trunc(timeMs / converter.ms)
    if (units > 0) {
      portions.push(units + ' ' + $t('timeDisplayUnits.' + converter.label))
      timeMs = timeMs - units * converter.ms
    }
  }
  return portions.join(' ')
}
