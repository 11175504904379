<template>
  <input
    ref="input"
    class="el-input__inner"
    v-mask="usePhoneMask ? phoneMask : mask"
    v-bind="$props"
    v-model="inputValue"
  />
</template>

<script>
import { mask } from 'vue-the-mask'
import { AsYouType } from 'libphonenumber-js'

const InputMask = (el, binding) => {
  if (!binding.value) return
  const tokens = el.__vue__?.tokens
  return tokens
    ? mask(el, {
        value: {
          mask: binding.value,
          tokens,
        },
      })
    : mask(el, binding)
}

export default {
  props: [
    'value',
    'mask',
    'usePhoneMask',
    'tokens',
    'disabled',
    'allowNumbers',
    'onlyUppercase',
  ],
  directives: { mask: InputMask },
  data() {
    return {
      inputValue: this.value,
    }
  },
  computed: {
    phoneMask() {
      let mask = new AsYouType().input(this.value).replace(/\d/g, '#')
      if (!mask.startsWith('+')) mask = '+' + mask
      return mask
    },
  },
  watch: {
    value(value) {
      let newValue = value
      if (this.allowNumbers === false) newValue = value.replace(/[0-9]/g, '')
      if (this.onlyUppercase === true) newValue = newValue.toUpperCase()
      this.inputValue = newValue
    },
    inputValue(value) {
      this.$emit('input', value)
      this.$refs.input?.parentElement?.parentElement?.__vue__?.validate()
    },
  },
}
</script>

<style lang="scss">
.el-input__inner[disabled] {
  background-color: #f5f7fa;
  border-color: #e4e7ed;
  color: #c0c4cc;
  cursor: not-allowed;
}
</style>
