<script>
export default {
  mounted() {
    if (document.readyState == 'complete') this.processLockedAttrsQuery()
    else window.addEventListener('load', () => this.processLockedAttrsQuery())
  },
  methods: {
    processLockedAttrsQuery() {
      for (const queryKey of Object.keys(this.$route.query)) {
        if (queryKey.startsWith('lockedAttrValues')) {
          const attrId = queryKey.slice('lockedAttrValues'.length + 1, -1)
          const attrValue = this.$route.query[queryKey]

          this.$store.lockedAttrValues[attrId] = attrValue
        }

        if (queryKey.startsWith('predefinedAttrValuesByType')) {
          const attrId = queryKey.slice(
            'predefinedAttrValuesByType'.length + 1,
            -1,
          )
          const attrValue = this.$route.query[queryKey]

          this.$store.predefinedAttrValuesByType[attrId] = attrValue
        }
      }
    },
  },
}
</script>
