<template>
  <div id="pwa-install-popup" :class="{ mobile: !$full }">
    <div
      class="popup"
      :class="[$store.device, !hide || 'hide', $full || 'mobile']"
    >
      <div class="content" v-if="$store.device != 'ios'">
        <img src="/favicon.ico" class="icon" />
        <div class="text" @click="install">{{ $t('pwa.text') }}</div>
        <div class="close" @click="close">
          <md-icon>close</md-icon>
        </div>
      </div>
      <div class="content" v-else @click="close">
        <img :src="require('@/assets/IosAdd.png')" class="icon" />
        <div class="text" @click="install">
          <span v-html="$t('pwa.textIos1')" />
          <img class="icon" :src="require('@/assets/IosShare.png')" />
          <span v-html="$t('pwa.textIos2')" />
        </div>
        <div class="shield" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      scrollHide: false,
      lastScrollY: 0,
    }
  },
  events: {
    'show-pwa-popup'() {
      if (localStorage.hidePwaPopup == 'true') return

      if (window.matchMedia('(display-mode: standalone)').matches) return
      if (navigator.standalone) return

      if (this.$store.pwaPrompt || ['ios'].includes(this.$store.device)) {
        this.show = true
        this.scrollHide = false
      }
    },
  },
  methods: {
    install() {
      this.$store.pwaPrompt?.prompt()
      this.$store.pwaPrompt?.userChoice.then(result => {
        if (result.outcome == 'accepted') {
          this.show = false
          this.$store.pwaPrompt = null
        }
      })
    },
    close() {
      this.show = false
      localStorage.hidePwaPopup = true
    },
    _scroll() {
      const scrollOffset = window.scrollY - this.lastScrollY
      const scrolledDown = scrollOffset > 0
      this.scrollHide = scrolledDown
      this.lastScrollY = window.scrollY
    },
  },
  computed: {
    hide() {
      if (!this.show) return true
      if (!this.$full && this.scrollHide) return true
      return false
    },
  },
}
</script>

<style lang="scss">
#pwa-install-popup {
  z-index: 16;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  pointer-events: none;
  align-items: center;

  &.mobile {
    top: initial;
    bottom: 0;
  }

  > .popup {
    height: min-content;
    pointer-events: initial;
    display: flex;

    transform: translateY(0);
    transition: 0.25s all;
    opacity: 1;
    &.hide {
      transform: translateY(-50px);
      pointer-events: none;
      opacity: 0;
    }

    > .content {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &.pc,
    &.mac,
    &.android {
      box-shadow: 0 1px 7px 1px rgb(0 0 0 / 15%);
      background-color: white;
      border-radius: 8px;
      padding: 10px;
      margin: 15px;

      > .content {
        > .icon {
          width: 26px;
          height: 26px;
        }
        > .text {
          font-size: 15px;
          color: #3475ca;
          cursor: pointer;
          margin: 0 5px;
        }
        > .close {
          cursor: pointer;
          > .md-icon {
            font-size: 21px !important;
          }
        }
      }
    }
    &.ios {
      box-shadow: 0 1px 15px 1px rgb(0 0 0 / 25%);
      background-color: white;
      border-radius: 8px;
      cursor: pointer;
      margin: 20px 0;
      padding: 8px;

      &.mobile {
        padding: 5px;
        > .content {
          > .shield {
            top: initial;
            bottom: -20px;
          }
        }
      }

      > .content {
        justify-content: flex-start;
        position: relative;
        > .icon {
          width: 32px;
          height: 32px;
          margin-right: 5px;
        }
        > .text {
          font-size: 13px;
          > .icon {
            height: 21px;
            margin: 0 5px;
          }
        }
        > .shield {
          transform: rotateZ(45deg) translateX(-50%);
          background-color: white;
          position: absolute;
          height: 26px;
          top: -5px;
          bottom: initial;
          width: 26px;
          z-index: -1;
          left: 50%;
        }
      }
    }
  }
}
</style>
