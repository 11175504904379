<template>
  <div id="notifications-screen" v-if="show && welcomed">
    <div class="blackout"></div>
    <div class="notifications-content">
      <div class="title">
        {{ $t('notifications.title1') }}
        <br />
        {{ $t('notifications.title2') }}
      </div>
      <div class="text">{{ $t('notifications.text') }}</div>

      <img :src="require('@/assets/Chatting.png')" />

      <div class="buttons">
        <div class="button" @click="allow">
          <div class="label">{{ $t('notifications.allow') }}</div>
        </div>

        <div class="button disabled" @click="disallow">
          <div class="label">{{ $t('notifications.disallow') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import settings from '@/settings'

export default {
  data() {
    return {
      show: false,
      welcomed: localStorage.welcomed,
    }
  },
  events: {
    welcomed() {
      this.welcomed = true
    },
  },
  mounted() {
    this.show =
      localStorage.notifications == undefined &&
      !this.$route.query.customerId &&
      Vue.firebase.messaging.isSupported &&
      window.Notification

    if (window.Notification?.permission == 'granted') {
      this.show = false
      localStorage.notifications = true
    }
    if (this.$store.isIframe) {
      this.show = false
    }

    if (localStorage.notifications == 'true') this.initPushToken()
  },
  methods: {
    disallow() {
      localStorage.notifications = false
      this.show = false
    },
    allow() {
      this.initPushToken()
      this.show = false
    },
    initPushToken() {
      if (localStorage.oldPushToken) {
        this.$api.defaults.headers.common.PushToken = localStorage.oldPushToken
      }

      Vue.firebase
        .messaging()
        .getToken({ vapidKey: settings.firebase.vapidKey })
        .then(token => {
          if (token && localStorage.oldPushToken !== token) {
            this.$api.post('user/push-token', { token }).then(() => {
              this.$api.defaults.headers.common.pushToken = token
              localStorage.oldPushToken = token
              localStorage.notifications = true
            })
          }
        })
        .catch(() => {
          localStorage.notifications = false
        })

      Vue.firebase.messaging().onMessage(payload => {
        this.$bvToast.toast(payload.notification.body, {
          title: payload.notification.title,
          variant: 'secondary',
          solid: true,
        })
      })
      this.$events.emit('check-bell')
    },
  },
}
</script>

<style lang="scss">
#notifications-screen {
  .blackout {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .notifications-content {
    z-index: 101;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    box-shadow: 0 1px 11px 1px rgba(0, 0, 0, 0.3);
    border-radius: 16px;
    width: 90vw;
    max-width: 400px;
    padding: 5px;

    .title {
      color: rgb(85, 95, 107);
      font-family: Oswald;
      font-weight: 300;
      font-size: 28px;
      line-height: 40px;
      margin-bottom: 20px;
      text-align: center;
    }

    .text {
      color: rgb(55, 59, 65);
      font-size: 17px;
      margin-bottom: 20px;
      text-align: center;
    }

    img {
      padding: 0 15px 0 15px;
      margin-bottom: 10px;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        position: relative;
        display: inline-block;
        background-color: #60a844;
        cursor: pointer;
        height: 60px;
        width: 130px;
        margin-right: 10px;
        margin-bottom: 20px;
        transition: 0.2s all;
        box-shadow: 0px 2px 4px 0px rgba(50, 50, 50, 0.4);

        &:hover {
          filter: brightness(1.1);
        }

        &.disabled {
          background-color: transparent;
          height: 30px;
          width: 100px;
          box-shadow: none;

          .label {
            color: rgb(177, 177, 177);
            text-transform: initial;
            text-decoration: underline;
          }
        }

        .label {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          color: white;
          text-align: center;
          width: 100%;
          text-transform: uppercase;
          font-size: 14px;
          font-family: Oswald;
        }
      }
    }
  }
}
</style>
