<script>
export default {
  created() {
    window.addEventListener('message', event => {
      if (!event.data?.type) return

      const { type, data } = event.data
      this.$events.emit('iframe.' + type, data)
    })

    this.$nextTick(() => {
      this.$events.emit('post-iframe', 'init')
      window.sessionStorage.clear()
    })
  },
}
</script>
