<script>
export default {
  created() {
    let get = (obj, prop, ...keys) => {
    if(obj[prop] == undefined) return undefined
    if(typeof obj[prop] != 'object') return obj[prop]
    return new Proxy(obj[prop], {
      set(target, objProp, value) {
        let storageKeys = [...keys, prop]
        let mainKey = storageKeys.shift()
        let mainObj = JSON.parse(sessionStorage[mainKey])
        
        let currentObj = mainObj
        for(let key of storageKeys) {
          if(typeof currentObj[key] == 'undefined') currentObj[key] = {}
          currentObj = currentObj[key]
        }
        currentObj[objProp] = value

        sessionStorage[mainKey] = JSON.stringify(mainObj)

        target[objProp] = value
        return true
      },
      deleteProperty(target, objProp) {
        let storageKeys = [...keys, prop]
        let mainKey = storageKeys.shift()
        let mainObj = JSON.parse(sessionStorage[mainKey])
        
        let currentObj = mainObj
        for(let key of storageKeys) {
          if(typeof currentObj[key] == 'undefined') currentObj[key] = {}
          currentObj = currentObj[key]
        }
        delete currentObj[objProp]

        sessionStorage[mainKey] = JSON.stringify(mainObj)

        delete target[objProp]
      },
      get(target, objProp) {
        return get(target, objProp, ...keys, prop)
      }
    })
  }

  this.$sessionStorage = new Proxy(sessionStorage, {
    get(storage, prop) {
      if(typeof storage[prop] == 'undefined') storage[prop] = '{}'
      let obj = JSON.parse(storage[prop])
      return new Proxy(obj, {
        set(target, objProp, value) {
          target[objProp] = value
          sessionStorage[prop] = JSON.stringify(target)
          return true
        },
        get(target, objProp) {
          return get(target, objProp, prop)
        }
      })
    },
    deleteProperty(target, objProp) {
      console.log(target)
      console.log(objProp)
    },
    set(storage, prop, value) {
      storage[prop] = JSON.stringify(value)
      return true
    }
  })
  }
}
</script>