<script>
export default {
  data() {
    return {
      canUpdatePwa: localStorage['welcomed'] == 'true',
    }
  },
  created() {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      this.$store.pwaPrompt = e
    })

    window.addEventListener('appinstalled', () => {
      this.$store.pwaPrompt = null
    })

    const { isPwa } = this.$store

    if (isPwa) this.logPwaInstalled()
    window.addEventListener('appinstalled', this.logPwaInstalled)

    document.addEventListener(
      'swUpdated',
      ({ detail: registration }) => {
        if (!registration?.waiting) return

        this.$bvToast.toast(this.$t('update.processing'), {
          title: this.$t('update.available'),
          variant: 'info',
          solid: true,
        })
        registration.waiting.postMessage('skipWaiting')
      },
      { once: true },
    )

    if (
      isPwa &&
      localStorage.lastOpenedHotelId &&
      window.location.pathname == '/'
    )
      this.$router.replace('/hotel/' + localStorage.lastOpenedHotelId)
  },
  methods: {
    logPwaInstalled() {
      if (!localStorage.pwaInstalledEventSent != 'true') {
        this.$events.emit('log-event', 'pwa_installed')
        localStorage.pwaInstalledEventSent = 'true'
      }
    },
  },
}
</script>
