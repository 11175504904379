<template>
  <div class="description-attribute">
    <p
      class="description"
      v-html="attribute.subTitle"
      v-html-reactive-links
      ref="description"
    />
  </div>
</template>
<script>
import AttributeMixin from '@/mixins/Attribute'

export default {
  props: ['attribute'],
  mixins: [AttributeMixin],
  computed: {
    subTitle() {
      return this.attribute.subTitle
        .replace(/\r\n/g, '<br>')
        .replace(/\r\n/g, '<br>')
        .replace(/\n/g, '<br>')
        .replace(/\r/g, '<br>')
    },
  },
  watch: {
    'attribute.subTitle': {
      immediate: true,
      handler: async function() {
        await this.$waitFor(() => this.$refs.description)
        for (const imgEl of this.$refs.description.getElementsByTagName(
          'img'
        )) {
          if (imgEl.getAttribute('configured-preview')) continue
          imgEl.setAttribute('configured-preview', true)

          imgEl.style.cursor = 'pointer'
          imgEl.onclick = () =>
            this.$events.emit('show-images', {
              src: imgEl.src,
            })
        }
      },
    },
  },
}
</script>

<style lang="scss">
.description-attribute {
  padding-top: 1rem;
  a {
    -webkit-text-fill-color: #448aff !important;
    &.next-button {
      -webkit-text-fill-color: white !important;
    }
  }
}
</style>
