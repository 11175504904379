<script>
export default {
  mounted() {
    if (document.readyState == 'complete') this.processCustomerIdQuery()
    else window.addEventListener('load', () => this.processCustomerIdQuery())
  },
  methods: {
    processCustomerIdQuery() {
      const queryCustomerId = this.$route.query.customerId
      if (!queryCustomerId) {
        this.$store.processedQueryAuth = true
        return
      }

      this.$store.setOverrideCustomerId(queryCustomerId)
      this.authorize()
      this.$events.emit('authorized')
      this.$events.emit('log-event', 'authenticated')
      this.$store.processedQueryAuth = true
    },
  },
}
</script>
