<script>
export default {
  created() {
    this.updateHeadTag()
  },
  methods: {
    updateHeadTag() {
      document.head.getElementsByTagName('title')[0].innerHTML = this.$t(
        'head.titleShort'
      )
      document
        .querySelector('meta[name="title"]')
        .setAttribute('content', this.$t('head.title'))
      document
        .querySelector('meta[name="description"]')
        .setAttribute('content', this.$t('head.description'))
      document
        .querySelector('meta[property="og:image"]')
        .setAttribute('content', '/img/icons/apple-touch-icon-152x152.png')
    },
  },
  watch: {
    currentLocale() {
      this.updateHeadTag()
    },
  },
}
</script>
