<template>
  <div class="attribute-wrapper mb-3" ref="main">
    <component
      :is="components[type]"
      v-show="!attribute.hidden"
      :attribute="attribute"
      :offer="offer"
      :subOffer="subOffer"
      :subSubOffer="subSubOffer"
    />
  </div>
</template>

<script>
import CheckAttribute from '@/components/Attributes/CheckAttribute'
import DateAttribute from '@/components/Attributes/DateAttribute'
import ListAttribute from '@/components/Attributes/ListAttribute'
import PrivacyPolicyAttribute from '@/components/Attributes/PrivacyPolicyAttribute'
import DescriptionAttribute from '@/components/Attributes/DescriptionAttribute'
import QtyAttribute from '@/components/Attributes/QtyAttribute'
import InputAttribute from '@/components/Attributes/InputAttribute'
import DateTimeAttribute from '@/components/Attributes/DateTimeAttribute'
import SlotsAttribute from '@/components/Attributes/SlotsAttribute'

export default {
  props: ['attribute', 'offer', 'subOffer', 'subSubOffer'],
  components: {
    CheckAttribute,
    DateAttribute,
    ListAttribute,
    PrivacyPolicyAttribute,
    QtyAttribute,
    InputAttribute,
    DateTimeAttribute,
    DescriptionAttribute,
    SlotsAttribute,
  },
  data() {
    return {
      type: this.attribute['@type'],
      components: {
        check: CheckAttribute,
        date: DateAttribute,
        list: ListAttribute,
        qty: QtyAttribute,
        privacy_policy: PrivacyPolicyAttribute,
        input: InputAttribute,
        cert: InputAttribute,
        name: InputAttribute,
        surname: InputAttribute,
        description: DescriptionAttribute,
        date_time: DateTimeAttribute,
        slots: SlotsAttribute,
      },
    }
  },
}
</script>

<style lang="scss">
.attribute-wrapper {
  *:not(.md-field.md-theme-default.md-has-value .md-input) {
    -webkit-text-fill-color: black;
    font-weight: 300;
    b,
    strong {
      font-weight: 500;
    }
  }
}
</style>
