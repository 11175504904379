<script>
export default {
  created() {
    this.setTheme('default')
  },
  methods: {
    setTheme(theme) {
      import(`@/scss/themes/${theme}.scss`).then(() => (this.theme = theme))
    },
  },
}
</script>
